module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: 'Beauty Salon Diva', // Navigation and Site Title
  siteTitleAlt: 'Beauty Salon Diva - Professionelle, günstige Massage in Uznach', // Alternative Site title for SEO
  siteHeadline: 'Wohltuende, professionelle Massagen in ruhiger Atmostphäre zu einem unschlagbaren Preis in Uznach.', // Headline for schema.org JSONLD
  siteTitleShort: 'DIVA', // short_name for manifest
  siteUrl: 'https://massage-uznach.ch', // Domain of your site. No trailing slash!
  siteLanguage: 'de', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: 'Professionelle Massagen aller Art in Uznach und Rapperswil. ',
  author: 'Suzana Dimic', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '', // Twitter Username
  ogSiteName: 'DIVA', // Facebook Site Name
  ogLanguage: 'de_CH',
  googleAnalyticsID: 'UA-143244765-1',

  // Manifest and Progress color
  themeColor: '#4d4d00',
  backgroundColor: '#fff',
}
