import React from 'react'
import styled from 'styled-components'
import AboutMe from './AboutMe'

const Wrapper = styled.footer`
  margin: 8rem 0 1rem 0;
  padding: 1rem ${props => props.theme.spacer.horizontal};
  text-align: center;
  font-size: 0.8rem;
  color: ${props => props.theme.colors.grey};
  a {
    text-decoration: none;
    color: ${props => props.theme.brand.primary};
  }
`

const Footer = () => (
  <>
    <AboutMe />
    <Wrapper data-testid="footer">
      Copyright &copy; 2019. All right reserved.{' '}
      <a href="https://salon-massage-diva.firebaseapp.com">Beauty Salon Diva</a> {'. Wichtige Information im '}{' '}
      <a href="/impressum">Impressum</a>.
    </Wrapper>
  </>
)

export default Footer
