import React from 'react'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'
// import { graphql } from 'gatsby'
// import Img from 'gatsby-image'

const Content = styled.div`
  display: flex;
  p {
    font-size: 22px;
    line-height: 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    flex-direction: column;
  }
`
const Imgs = styled.div`
  img {
    width: 500px;
    max-width: 100%;
    margin-left,margin-right: auto;
  }
`

const LeftFooter = styled.div`
  width: 50%;
  padding: 30px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`

const RightFooter = styled.div`
  width: 50%;
  padding: 30px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
    padding: 30px 0;
    h1 {
      padding-left: 30px;
      padding-right: 30px;
    }
    p {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
`
const isClient = typeof window !== 'undefined'

// eslint-disable-next-line react/prop-types
const MapMarker = ({ text }) => <div style={{ color: '#ce1691', fontSize: '16px' }}>{text}</div>

export const GoogleMap = () => {
  const googleMapsApiKey = 'AIzaSyCv60c9kN60npA311ACf6fBkRWaDGLvH0Y'
  const lat = 47.2254606 // parseFloat(address.lat)
  const lng = 8.9793236 // parseFloat(address.lng)
  return (
    <section className="google-map">
      <div className="map" style={{ height: '500px', width: '100%' }}>
        {isClient && (
          <GoogleMapReact bootstrapURLKeys={{ key: googleMapsApiKey }} defaultCenter={[lat, lng]} defaultZoom={17}>
            <MapMarker
              lat={lat}
              lng={lng}
              text="Beauty Salon Diva"
              icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
            />
          </GoogleMapReact>
        )}
      </div>
    </section>
  )
}

export const GoogleMap2 = () => {
  const googleMapsApiKey = 'AIzaSyCv60c9kN60npA311ACf6fBkRWaDGLvH0Y'
  const lat = 47.226289 // parseFloat(address.lat) 47.226289,
  const lng = 8.8153721 // parseFloat(address.lng)
  return (
    <section className="google-map">
      <div className="map" style={{ height: '500px', width: '100%' }}>
        {isClient && (
          <GoogleMapReact bootstrapURLKeys={{ key: googleMapsApiKey }} defaultCenter={[lat, lng]} defaultZoom={17}>
            <MapMarker
              lat={lat}
              lng={lng}
              text="Beauty Salon Diva"
              icon="https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"
            />
          </GoogleMapReact>
        )}
      </div>
    </section>
  )
}

const AboutMe = () => (
  <Content>
    <LeftFooter>
      <h1>Etwas über mich</h1>
      <p>
        Ich bin <strong>Suzana Dimic</strong>, diplomierte Wellnessmasseurin.
        <br />
        Seit 2015, nach der erfolgreichen Absolvierung der Massage Fachschule Zürich, massiere ich mit einer natürlichen
        Herangehensweise und breitem Wissen. Massage ist meine Passion und ich setze diese täglich in einer
        wirkungsvollen Behandlung ein.
      </p>
      <Imgs>
        <img src="../images/img1.jpg" alt="Suzana Dimic Bild in der Praxis" />
      </Imgs>
      <br />
      <Imgs>
        <img src="../images/Diplom_Abnehmen_im_Liegen.jpg" alt="Abnehmen im Liegen Diplom" />
      </Imgs>
      <br />
      <Imgs>
        <img src="../images/Diplom_Haarentfernung_mit_Laser.jpg" alt="Haarentfernung mit Laser Diplom" />
      </Imgs>
      {/* <Img fixed={data.file.childImageSharp.fixed} /> */}
    </LeftFooter>
    <RightFooter>
      <h1>Wie Sie uns finden</h1>
      <p>Vereinbaren Sie einen Termin.</p>
      <h3>Rapperswil</h3>
      <p>
        Mittwoch 14.00 - 21.00
        <br />
        Donnerstag 09.00 - 16.00
        <br />
        Freitag 14.00 - 21.00
        <br />
        Samstag 09.00 - 14.00
      </p>
      <p>
        Marktgasse 12
        <br />
        8640 Rapperswil
        <br />
        <a href="tel:+41767970307">076 797 03 07</a>
        <br />
      </p>
      <GoogleMap2 />

      <h3>Uznach</h3>
      <p>Montag, Termin nach Vereinbarung</p>
      <p>
        Gallusstr. 1a
        <br />
        8730 Uznach
        <br />
        <a href="tel:+41767970307">076 797 03 07</a>
        <br />
      </p>
      <GoogleMap />
    </RightFooter>
  </Content>
)

export default AboutMe

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "../images/img1.jpg" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed(width: 500, quality: 90) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `

// Dimic Suzana je rodjena 18.08.1981. Godine u Srbiji. Od 2002. godine zivi i radi u Svajcarskoj. Udata, majka dvoje dece.
// Masazom je aktivno pocela da se bavi 2015. Godine kada je uspesno zavrsila ,,Massage Fach Schule,, u Cirihu.
// Ozbiljan pristup, siroka baza znanja i mogucnost sticanja znacajnog iskustva je ono sto me je motivisalo da ulozim veliki trud i rad u obavljanju ovog posla. Efikasnim obavljanjem svog posla nagradjena sam mogucnoscu da se i dalje uspesno i profesionalno usavrsavam.
