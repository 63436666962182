import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { FaFacebook } from 'react-icons/fa'
import styled from 'styled-components'
import config from '../../config/website'
import { BookingButton } from '.'

const Wrapper = styled.header`
  align-items: center;
  display: flex;
  padding: 10px 0;
  position: relative;
  z-index: 1000;
  a {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    z-index: 100;
    &:hover {
      color: ${props => props.theme.brand.primary};
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`

// const Nav = styled.nav`
//   display: flex;
//   flex: 4 1 fit-content;
//   justify-content: center;
//   padding: 0 ${props => props.theme.spacer.horizontal};
//   a:not(:first-child) {
//     margin-left: 1rem;
//   }
//   @media (max-width: ${props => props.theme.breakpoints.s}) {
//     padding: 0 1rem;
//   }
//   @media (max-width: ${props => props.theme.breakpoints.xs}) {
//     order: 2;
//     flex: 2 0 80%;
//     justify-content: flex-start;
//   }
// `

const Logo = styled.div`
  display: flex;
`

const Name = styled.div`
  display: flex;
  flex: 2 1 auto;
  justify-content: flex-start;
  padding-left: 2rem;
  a {
    font-size: 2rem;
    font-family: 'Sacramento', -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
    font-weight: 700;
    &:hover,
    &:focus {
      color: ${props => props.theme.colors.body_color};
      text-decoration: none;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    order: 1;
    flex: 1 0 100%;
    margin-bottom: 0.75rem;
    justify-content: center;
    padding: 10px 0;
  }
`

const SocialMedia = styled.div`
  display: flex;
  flex: 1 1 fit-content;
  justify-content: flex-end;
  padding: 0 ${props => props.theme.spacer.horizontal};
  a {
    font-size: 1.25rem;
    line-height: 20px;
  }
  a:not(:first-child) {
    margin-left: 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 1rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    order: 3;
    flex: 1 0 20%;
    justify-content: center;
  }
`

const JetztBuchen = styled.div`
  display: flex;
  // flex: 2 1 auto;
  justify-content: flex-end;
  padding: 0 0;
  a {
    font-size: 1.2rem;
    line-height: 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0 0rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.xs}) {
    order: 4;
    flex: 1 0 80%;
    justify-content: center;
  }
`

// Grabs all MDX files from src/pages and puts them into the navigation

const Navigation = () => {
  const data = useStaticQuery(query)

  return (
    <Wrapper data-testid="navigation">
      <Logo>
        <Link to="/">
          <img src="logo_200x200.png" alt="Salon Massage Diva Logo" width="200px" />
        </Link>
      </Logo>
      <Name>
        <Link to="/" data-testid="home-title-link">
          {config.siteTitle}
        </Link>
      </Name>
      {/* <Nav>
        {data.nav.nodes.map((n, index) => (
          <Link key={n.fields.slug} to={n.fields.slug} data-testid={`navItem-${index}`} activeClassName="nav-active">
            {n.frontmatter.title}
          </Link>
        ))}
      </Nav> */}
      <SocialMedia>
        <a
          href="https://www.facebook.com/Salon-Massage-DIVA-832174060501981/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Facebook"
        >
          <FaFacebook />
        </a>
      </SocialMedia>
      <JetztBuchen>
        <BookingButton />
      </JetztBuchen>
    </Wrapper>
  )
}

export default Navigation

const query = graphql`
  query NavLinks {
    nav: allMdx(filter: { fields: { sourceInstanceName: { eq: "pages" } } }) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`
