import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
`

const Number = styled.div`
  color: #fff;
  padding: 8px 12px;
  border: 1px;
  border-color: #000;
  border-radius: 2px;
  text-decoration: none;
  text-align: center;
  span {
    border: 1px black !important;
    padding-top: 2px;
  }
`

const Address = styled.div`
  color: #333;
  span {
    font-size: 0.7rm !important;
  }
  justify-content: center;
  text-align: center;
  padding-right: 30px;
`

const BookingButton = () => (
  <Wrapper>
    <Address>
      <span>Gallusstr. 1a, 8730 Uznach</span>
      <br />
      <span>Marktgasse 12, 8640 Rapperswil</span>
      <br />
      <strong>
        <a href="tel:+41767970307">076 797 03 07</a>
      </strong>
    </Address>
    <Number>
      <span>
        <a href="tel:+41767970307">Termin vereinbaren</a>
      </span>
    </Number>
  </Wrapper>
)

export default BookingButton
